import React, { lazy } from "react";
import withTheme from "../../hoc/withTheme";

const NewOpportunity = lazy(() => import("./challengeOpportunity"));

const ChallengeOpportunityClientWrapper = () => {
  return <NewOpportunity />;
};

export default withTheme(ChallengeOpportunityClientWrapper);
