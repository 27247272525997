const constants = {
  colors: {
    primaryBackgroundLight: "#F5F3F7",
    primaryBackgroundSelected: "#150B3B",
    primaryBackground: "#211551",
    primaryLightFill: "#EAE9F2",
    primarySeparator: "#A199C2",
    primaryTextHover: "#6855B4",
    primaryTextSelected: "#22135E",
    atRiskBackground: "#F37026",
    offTrackBackground: "#C84060",
    offTrackText: "#B41320",
    actionBackground: "#1559EA",
    actionBackgroundDisabled: "#1559EA40",
    actionBackgroundSelected: "#1046B8",
    colorAction300: "#3270EB",
    neutralsBackgroundInactive: "rgba(255, 255, 255, 0.60)",
    neutralsText: "#100A27",
    neutralsTextLight: "#575463",
    neutralsTextDisabled: "#B8B3BC",
    neutralsWhite: "#FFFFFF",
    neutralsWhiteTint30: "#FFFFFF4D",
    neutralsWhiteTint60: "#FFFFFF99",
    neutralsWhiteTint85: "#FFFFFFD9",
    primaryText: "#352281",
    primaryRulerTint50: "rgba(161, 153, 194, 0.50)",
    primaryRulerTint15: "rgba(161, 153, 194, 0.15)",
    primaryTextTint08: "#6855B414",
    neutralsTextTint85: "rgba(255, 255, 255, 0.85)",
    neutralsBlack: "#080F1B",
    secondaryBackground: "#007F9C",
    secondaryBackgroundLightTint20: "rgba(213, 247, 255, 0.20)",
    supplementalGroupBackground: "#faf9fb",
    disabledInputBackground: "hsl(0, 0%, 95%)",
    ribbonColors: {
      first: {
        top: "#A199C2",
        left: "#211551",
        right: "#6855B4",
      },
      second: {
        top: "#D5F7FF",
        left: "#00576B",
        right: "#0095B8",
      },
      third: {
        top: "#EAE9F2",
        left: "#4C475D",
        right: "#838C9F",
      },
    },
  },
};

export default constants;
